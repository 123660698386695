import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { screenMargin } from '_utils/sizes';
import TemplateSelector from './TemplateSelector';
import { WH, WW } from '../../../../../_utils';
import { useSelectedCoopItem } from '../../../../../_utils/hooks';
import {
    HeaderWithNav,
    Icon,
    PrimaryButton,
    FormInput,
    FormDateTimeInput,
    CooperativeGroupDropdown,
    HeimeText,
} from '../../../../../Components';
import { FormValues } from '../../schema';
import { CreateTitle, PageContainer, SectionTitle } from '../common';
import { useStartEndTimeSync, useTimeSettings } from '../hooks';

const ActivityAbout = ({ nextPage, goBack }: { nextPage(): void; goBack(): void }): ReactElement => {
    const { t } = useTranslation();
    const { handleSubmit, setValue, formState, watch } = useFormContext<FormValues>();
    const selectedCoop = useSelectedCoopItem();
    const showCooperativeGroupDropdown = (selectedCoop?.cooperative_groups ?? []).length > 1;

    const { start: startSettings, end } = useTimeSettings();

    const onSubmit = (values: FormValues) => {
        if (!values.name) {
            return;
        }
        if (!values.start_at) {
            setValue('start_at', startSettings.defaultValue);
        }
        if (!values.end_at) {
            setValue('end_at', end.defaultValue);
        }

        nextPage();
    };

    const isNew = watch('id') === undefined;

    const name = watch('name');
    const disableButton = formState.errors.name || !name;

    useStartEndTimeSync();

    return (
        <>
            <HeaderWithNav onPress={goBack} title={t('newActivity:title_create_activity')} />
            <PageContainer disableHorizontalPadding>
                <HorizontalPadding>
                    <CreateTitle>{t('newActivity:about_activity')}</CreateTitle>
                </HorizontalPadding>
                <TemplateSelector />
                <HorizontalPadding>
                    <FormInput
                        placeholder={t('newActivity:enter_title')}
                        name="name"
                        label={t('newActivity:title')}
                        autoFocus
                    />
                    <FormInput
                        optional
                        placeholder={t('newActivity:enter_location')}
                        name="location"
                        label={t('newActivity:location')}
                        leftImage={<Icon name="location" color="mediumGrey" />}
                    />
                    <SectionTitle>{t('newActivity:time')}</SectionTitle>
                    <View style={styles.rowFlex}>
                        <FormDateTimeInput
                            label={t('newActivity:start')}
                            mode="datetime"
                            labelStyles={styles.minWidthLabel}
                            {...startSettings}
                        />
                        <FormDateTimeInput mode="time" {...startSettings} />
                    </View>

                    <View style={styles.rowFlex}>
                        <FormDateTimeInput
                            label={t('newActivity:end')}
                            mode="datetime"
                            labelStyles={styles.minWidthLabel}
                            {...end}
                        />
                        <FormDateTimeInput mode="time" {...end} />
                    </View>
                    {showCooperativeGroupDropdown ? <CooperativeGroupDropdown isCreatingNew={isNew} /> : null}
                    <FormInput
                        placeholder={t('newActivity:enter_description')}
                        name="description"
                        label={t('newActivity:description')}
                        multiline
                    />
                    <HeimeText variant="subtitle" style={{ marginVertical: screenMargin }}>
                        {t('newActivity:activity_about_description')}
                    </HeimeText>
                </HorizontalPadding>
            </PageContainer>
            <PrimaryButton
                status={disableButton ? 'disabled' : null}
                text={t('newActivity:continue').toUpperCase()}
                onPress={handleSubmit(onSubmit)}
                bottomAction="modal"
            />
        </>
    );
};

const HorizontalPadding = ({ children }: { children: React.ReactNode }) => (
    <View style={{ paddingLeft: screenMargin, paddingRight: screenMargin }}>{children}</View>
);

const styles = StyleSheet.create({
    rowFlex: { display: 'flex', flexDirection: 'row', marginTop: WH * 0.01 },
    minWidthLabel: { minWidth: WW * 0.1 },
});

export default ActivityAbout;
