import { useCallback } from 'react';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { dismissAddProfile } from '_redux/modals';
import { invalidateUsersQuery } from './useUsers';
import { useGetAccessTokenHeader } from '../_utils/Axios';
import { useSelectedCoop } from '../SelectedCoop';
import { OwnUserProfile } from '../types/User';
import { Base64EncodedFile, UnixTimeCode } from '../types/Utility';

interface Role {
    id: number;
    name: string;
}
interface ProfileGetData {
    id: number;
    rating: number;
    can_give_ratings: boolean;
    email?: string;
    phone?: string;
    fname: string;
    lname: string;
    cooperative_roles: Role[];
    cooperative_apartments: { id: number; code: string; cooperative_id: number }[];
    gdpr: boolean;
    about_me: string;
    hide_mail: boolean;
    hide_phone: boolean;
    hide_birthday: boolean;
    birthday?: UnixTimeCode;
    avatar: Base64EncodedFile;
    stop_neighbor_messages_notification: boolean;
    stop_board_messages_notification: boolean;
}

const getOwnProfile = async (getAuthHeader: () => Promise<string>) => {
    const authHeader = await getAuthHeader();
    const profileResult = await axios.get<ProfileGetData>('profile', {
        headers: { authorization: authHeader },
    });
    return profileResult.data;
};

const usePrefetchProfile = (): (() => void) => {
    const queryClient = useQueryClient();
    const getAUthHeader = useGetAccessTokenHeader();

    return useCallback(() => {
        const data = queryClient.getQueryData(profileQueryKey);
        if (!data) {
            queryClient.prefetchQuery({ queryKey: profileQueryKey, queryFn: () => getOwnProfile(getAUthHeader) });
        }
    }, [getAUthHeader, queryClient]);
};

const profileQueryKey = ['profile'];

const useOwnProfile = (): UseQueryResult<OwnUserProfile, string | Error | AxiosError> => {
    const getAUthHeader = useGetAccessTokenHeader();
    return useQuery({
        queryKey: profileQueryKey,
        queryFn: () => getOwnProfile(getAUthHeader),
        staleTime: 1000 * 60 * 60 * 24 * 7,
        gcTime: Infinity,
    });
};

interface ProfilePatchData {
    email?: string;
    birthday?: UnixTimeCode;
    avatar?: string | null;
    gdpr?: boolean;
    phone?: string;
    fname?: string;
    lname?: string;
    about_me?: string;
    own_code?: string;
    hide_mail?: boolean;
    hide_phone?: boolean;
    hide_birthday?: boolean;
    stop_neighbor_messages_notification?: boolean;
    stop_board_messages_notification?: boolean;
}

const useUpdateProfile = (): UseMutationResult<void, string | Error, ProfilePatchData> => {
    const getAuthHeader = useGetAccessTokenHeader();
    const selectedCoopId = useSelectedCoop();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: async (profile: ProfilePatchData) => {
            const result = await axios.patch<{ success: boolean }>(`cooperatives/${selectedCoopId}/profile`, profile, {
                headers: { authorization: await getAuthHeader() },
            });
            if (!result.data.success) {
                throw new Error('Result returned with success == false');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: profileQueryKey,
            });
            invalidateUsersQuery(queryClient);

            // We do this to prevent the modal from showing up straight after editing / or removing
            dispatch(dismissAddProfile(moment().add({ hour: 1 }).unix()));
        },
    });
};

export { useUpdateProfile, useOwnProfile, usePrefetchProfile };
