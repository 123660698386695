import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore, createStore, Store } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore, Persistor } from 'redux-persist';
import deviceToken from './deviceToken';
import licensePlate from './licensePlate';
import addProfileModal from './modals';
import pendingRegistration from './pendingRegistration';
import selectedCoop from './selectedCoop';
import selectedLanguage from './selectedLanguage';
import settings from './settings';
import timeSync from './timeSync';

const reducerObject = {
    settings,
    selectedCoop,
    addProfileModal, // Legacy name
    selectedLanguage,
    timeSync,
    pendingRegistration,
    licensePlate,
    deviceToken,
};
const getReduxSetup = (): { store: Store; persistor: Persistor } => {
    // Here for easy enabling of redux logging
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const logger = createLogger({
        // ...options
    });

    const persistConfig = {
        key: 'Heime',
        storage: AsyncStorage,
    };

    const rootReducer = persistReducer(persistConfig, combineReducers(reducerObject));

    const store = createStore(rootReducer);

    return { store, persistor: persistStore(store) };
};

const exampleStore = configureStore({
    reducer: reducerObject,
});

export type GlobalState = ReturnType<typeof exampleStore.getState>;
export type AppDispatch = typeof exampleStore.dispatch;

export { getReduxSetup };
