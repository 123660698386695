import React, { useEffect } from 'react';
import { suggestions } from '_api/useActivityInterest';
import { useAppNavigation } from '_navigator';
import { track } from '_utils/Amplitude';
import { captureException } from '_utils/Sentry';
import { ArrayElement } from 'types/Utility';
import { DirectPress, ImagesBanner, PressHere } from './variants';

const variants = ['press_here', 'images_banner', 'direct_press'];
const variant = variants[Math.floor(Math.random() * variants.length)];
const language_variant = Math.random() > 0.5 ? 'suggestion' : 'other_neighborhood';
const emoji = variant === 'direct_press' && Math.random() < 0.5;

const EmptyActivitiesPreview = () => {
    const { navigate } = useAppNavigation();

    const goToActivitySuggestions = (suggestion?: ArrayElement<typeof suggestions>) => {
        if (suggestion) {
            track('Pressed activity suggestions CTA', {
                variant,
                suggestion,
                emoji,
            });
            navigate('ActivitySuggestionSelected', { suggestion });
        } else {
            track('Pressed activity suggestions CTA', { variant, language_variant, emoji });
            navigate('ActivitySuggestions', undefined);
        }
    };

    useEffect(() => {
        track('Showing activity suggestions CTA', { variant, language_variant, emoji });
    }, []);

    switch (variant) {
        case 'press_here':
            return <PressHere language_variant={language_variant} onPress={() => goToActivitySuggestions()} />;
        case 'images_banner':
            return <ImagesBanner language_variant={language_variant} onPress={() => goToActivitySuggestions()} />;
        case 'direct_press':
            return <DirectPress emoji={emoji} onPress={goToActivitySuggestions} />;
    }
    captureException(new Error(`Unknown variant: ${variant}`));
    return null;
};

export default EmptyActivitiesPreview;
