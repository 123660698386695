import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { cleanPhoneNumber } from 'types/User';
import { Title } from '.';
import BackButton from './BackButton';
import { authorization } from '../../_api';
import { useThemeStyle, WH, Theme, isAppError, isNetworkError } from '../../_utils';
import { captureException } from '../../_utils/Sentry';
import { subtitleFontSize } from '../../_utils/sizes';
import { LanguageSelector, PhoneNumberInput, PrimaryButton } from '../../Components';
import { useAuthNavigation, useAuthRoute } from '../AuthNavigation';

const EnterPhone = (): ReactElement => {
    const themedStyle = useThemeStyle(styles);
    const navigation = useAuthNavigation();
    const { params } = useAuthRoute<'EnterPhone'>();

    const args = useMemo(
        () =>
            !params
                ? { state: 'initial' as const }
                : 'enteredPhone' in params
                  ? { state: 'enteredPhone' as const, ...params }
                  : { state: 'phoneHint' as const, ...params },
        [params],
    );

    const [phoneNumber, setPhoneNumber] = useState(args.state === 'phoneHint' ? (args.phoneHint ?? '') : '');
    const [status, setStatus] = useState<'loading' | 'errorGeneric' | 'errorNoNetwork' | null>(null);

    useEffect(() => {
        if (args.state === 'phoneHint') {
            setPhoneNumber(args.phoneHint ?? '');
        }
    }, [args]);

    const isError = status?.startsWith('error');

    const { t } = useTranslation();

    const handleChangeNumber = (newVal: string) => {
        if (isError) {
            setStatus(null);
        }
        setPhoneNumber(cleanPhoneNumber(newVal));
    };

    const handleSubmit = async () => {
        if (status === 'loading' || !phoneNumber) {
            return;
        }

        const handleUnknownNumber = () => {
            setStatus(null);
            if (args.state === 'enteredPhone') {
                if (phoneNumber === args.enteredPhone) {
                    navigation.push('ConfirmUser', {
                        phone: args.enteredPhone,
                    });
                } else {
                    navigation.push('SelectNumber', {
                        firstNumber: phoneNumber,
                        secondNumber: args.enteredPhone,
                    });
                }
            } else {
                navigation.push('EnterPhone', {
                    enteredPhone: phoneNumber,
                });
            }
            setPhoneNumber('');
        };

        const func = async (retries = 0): Promise<ReturnType<typeof authorization.checkAsync>> => {
            try {
                return await authorization.checkAsync(phoneNumber);
            } catch (ex: unknown) {
                if (isAppError(ex) && isNetworkError(ex)) {
                    if (retries > 2) {
                        throw ex;
                    }
                    return await func(retries + 1);
                }
                throw ex;
            }
        };

        try {
            setStatus('loading');
            const valueToSend = phoneNumber;
            const result = await func();
            if (result?.data?.success) {
                const isOtp = result.data.sms_code_send;
                navigation.push('EnterPin', {
                    status: isOtp ? 'enterOTP' : 'enterOwnPin',
                    phone: valueToSend,
                });
                setStatus(null);
            } else {
                if (
                    result.status === 422 &&
                    (result?.data as unknown as { errors?: { phone?: string[] } })?.errors?.phone?.find(
                        (el: string) => el === 'Phone not found',
                    )
                ) {
                    handleUnknownNumber();
                } else {
                    setStatus('errorGeneric');
                }
            }
        } catch (ex: unknown) {
            if (
                isAppError<{ phone: string }>(ex) &&
                ex?.response?.data?.errors?.phone?.find((el: string) => el === 'Phone not found')
            ) {
                handleUnknownNumber();
            } else if (isAppError(ex) && isNetworkError(ex)) {
                setStatus('errorNoNetwork');
            } else {
                captureException(ex);
                setStatus('errorGeneric');
            }
        }
    };

    const enteredPhone =
        args.state === 'enteredPhone' && isValidNumber('+' + args.enteredPhone)
            ? parsePhoneNumber('+' + args.enteredPhone)
            : null;

    return (
        <>
            {args.state === 'enteredPhone' ? <BackButton /> : <LanguageSelector />}
            <View>
                <Title text={args.state !== 'enteredPhone' ? t('enterPhone:title') : t('enterPhone:repeatNumber')} />
                <Text style={themedStyle.descriptionText}>
                    {args.state !== 'enteredPhone'
                        ? t('enterPhone:subText')
                        : t('enterPhone:repeatNumberSubText', {
                              enteredNumber: enteredPhone?.formatInternational() ?? args.enteredPhone,
                          })}
                </Text>
                <PhoneNumberInput
                    placeholder={t('enterPhone:inputPlaceholder')}
                    value={phoneNumber}
                    onChange={handleChangeNumber}
                    autoFocus
                    onSubmitEditing={handleSubmit}
                    error={status?.startsWith('error')}
                    testID="enterPhone:input"
                    clearButtonMode="unless-editing"
                    initialCountry={enteredPhone ? null : undefined}
                    returnKeyType="go"
                />
            </View>
            <View style={themedStyle.bottomContainer}>
                {isError ? (
                    <Text style={themedStyle.errorMessage}>
                        {status === 'errorNoNetwork' ? t('enterPhone:errorNoNetwork') : t('enterPhone:errorGeneric')}
                    </Text>
                ) : null}
                <PrimaryButton
                    text={t('enterPhone:button').toUpperCase()}
                    style={themedStyle.continueButton}
                    status={!phoneNumber ? 'disabled' : status === 'loading' ? 'loading' : null}
                    onPress={handleSubmit}
                />
            </View>
        </>
    );
};

const styles = (theme: Theme) =>
    StyleSheet.create({
        descriptionText: {
            marginTop: WH * 0.05,
            marginBottom: WH * 0.05,
            textAlign: 'left',
            fontSize: subtitleFontSize,
        },
        bottomContainer: {
            width: '100%',
        },
        errorMessage: {
            marginTop: 16,
            marginBottom: 16,
            color: theme.error,
            fontSize: 16,
            textAlign: 'center',
        },
        continueButton: {
            width: '100%',
        },
    });

export default EnterPhone;
