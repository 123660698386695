import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnixTimeCode } from '../types/Utility';

export type ModalState = {
    /**
     * The UNIX time  when the add profile modal should be shown again.
     */
    showAfter: UnixTimeCode | null;
    activityNewAbortQuestionAskedAt: UnixTimeCode | null;
    activitySuggestionAskedAt: UnixTimeCode | null;
};

const initialState: ModalState = {
    showAfter: null,
    activityNewAbortQuestionAskedAt: null,
    activitySuggestionAskedAt: null,
};

const modalSlice = createSlice({
    name: 'addProfileModal', // Legacy name
    initialState,
    reducers: {
        dismissAddProfile: (state, action: PayloadAction<UnixTimeCode>) => {
            if (state.showAfter && state.showAfter > action.payload) {
                return;
            }
            state.showAfter = action.payload;
        },
        askActivityNewAbort: (state, action: PayloadAction<UnixTimeCode>) => {
            state.activityNewAbortQuestionAskedAt = action.payload;
        },
        askActivitySuggestionAbort: (state, action: PayloadAction<UnixTimeCode>) => {
            state.activitySuggestionAskedAt = action.payload;
        },
    },
});

export const { dismissAddProfile, askActivityNewAbort, askActivitySuggestionAbort } = modalSlice.actions;

export default modalSlice.reducer;
